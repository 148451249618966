<template>
  <div class="project-detail-for-admin" :class="{skeleton: !state.loaded }">
    <div class="title font-md">
      <b>프로젝트 대회 정보</b>
    </div>
    <div class="desc font-sm">
      <span>관리자용 프로젝트 대회 정보입니다.</span>
    </div>
    <div class="content">
      <table class="table font-sm">
        <tbody>
        <tr>
          <th>
            <span>대회 여부</span>
          </th>
          <td>
            <span>{{ state.partnerships.length ? "대회" : "일반" }}</span>
          </td>
        </tr>
        <tr v-if="state.partnerships.length">
          <th>
            <span>대회</span>
          </th>
          <td></td>
        </tr>
        </tbody>
      </table>
      <ul class="tight font-sm" v-if="state.partnerships.length">
        <li v-for="(p, idx) in state.partnerships" :key="idx">
          <span>
            <span v-if="p.contestType === 'E'">[전용관]</span>
            <span v-else-if="p.contestType === 'S'">[모의투자]</span>
            <span v-else>[파트너사]</span>
            <span> {{ p.contestName }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "modalProjectDetailForAdmin";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < 2; i += 1) {
        state.partnerships.push({
          contestName: "Please wait a moment"
        });
      }

      state.loaded = false;
      http.get(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/admin-partnerships`).then(({data}) => {
        state.loaded = true;
        state.partnerships = data.body;
      });

      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      loaded: false,
      partnerships: []
    });

    const modalParams = store.getters.modalParams(component);

    return {component, state, modalParams};
  }
});
</script>

<style lang="scss" scoped>
.project-detail-for-admin {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  .content {
    table {
      tr {
        th, td {
          border: 0;
        }

        th {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          font-weight: normal;
          width: $px90;
        }

        &:first-child {
          th, td {
            padding-top: 0;
          }
        }

        &:last-child {
          th, td {
            padding-bottom: 0;
          }
        }
      }
    }

    ul {
      padding-top: $px10;

      > li:not(:last-child) {
        margin-bottom: $px5;
      }
    }
  }

  &.skeleton {
    .title > b, .desc > span {
      @include skeleton;
    }

    .content {
      table {
        th, td {
          > span {
            @include skeleton;
          }
        }
      }

      ul > li > span {
        @include skeleton;
      }
    }
  }
}
</style>